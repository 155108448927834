import { Stack, useDisclosure } from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { Authorizer } from './utils/authorize';
import { createInterceptor } from './utils/interceptor';
import AppRoutes from './app-routes';
import ErrorDialog from './components/errorDialog';
import Header from './components/header';
import { buildHandleAxiosError } from './utils/axiosUtil';
import { FeatureFlagsProvider } from './context/feature-flags.context';
import { ConfigContext } from './context/config.context';
import { ApiClientsProvider } from './context/api-clients.context';

const App = () => {
	const [isAuthReady, setIsAuthReady] = useState(false);
	const [loading, isLoading] = useState(true);
	const [isBootstrapped, setIsBootstrapped] = useState(false);
	const [messages, setMessages] = useState<Record<string, string>>();
	const [appConfig, setAppConfig] = useState<Record<string, any>>();

	const [userErrorMessage, setUserErrorMessage] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleAxiosError = useMemo(
		() =>
			buildHandleAxiosError((errorMessage: string) => {
				setUserErrorMessage(errorMessage ?? '');
				onOpen();
			}),
		[onOpen]
	);

	const validateAuthentication = async () => {
		try {
			const loginResponse = await Authorizer.validateToken(() =>
				axios.get('/api/login')
			);
			setIsAuthReady(!!loginResponse);
		} catch (err) {
			Authorizer.redirect();
		}
	};

	useEffect(() => {
		if (isAuthReady && isBootstrapped) {
			isLoading(false);
		}
	}, [isAuthReady, isBootstrapped]);

	useEffect(() => {
		(async () => {
			if (isBootstrapped) {
				return;
			}

			// Validate login/cookie
			const { data } = await axios.get('/api/authURL');
			Authorizer.init(data.authURL);
			await validateAuthentication();

			// APIs can be done in parallel now that we are past auth.
			// Don't throw errors if these fail; UI can still load without them.
			const getMessages = axios.get<Record<string, string>>('/api/messages');
			const getConfig = axios.get('/api/config');
			const responses = await Promise.allSettled([getMessages, getConfig]);
			if (responses[0]?.status === 'fulfilled' && responses[0].value?.data) {
				setMessages(responses[0].value.data);
			}
			if (responses[1]?.status === 'fulfilled' && responses[1]?.value?.data) {
				const cfgResponse = responses[1].value.data || {};
				setAppConfig(cfgResponse);

				const { token, application } = cfgResponse.trackJsConfig || {};
				const { TrackJS } = window as any;
				if (
					process.env.NODE_ENV === 'production' &&
					token &&
					application &&
					TrackJS
				) {
					TrackJS.install({
						token,
						application,
					});
				}
			}

			createInterceptor(() => {
				Authorizer.redirect();
			});

			setIsBootstrapped(true);
		})();
	}, [handleAxiosError, isBootstrapped]);

	if (loading) {
		return (
			<div className="spinner-container" role="main">
				<div className="spinner" aria-label="Loading" />
				<div className="inner-spinner" />
			</div>
		);
	}

	return (
		<IntlProvider
			defaultLocale="en"
			locale={navigator.languages?.[0] ?? navigator.language}
			messages={messages}
		>
			<FeatureFlagsProvider
				appUser={Authorizer.getAuthData()}
				ldKey={appConfig?.launchDarkly}
			>
				<ConfigContext.Provider value={appConfig || {}}>
					<ApiClientsProvider>
						<Router>
							<Stack padding={3}>
								<Header />
								<AppRoutes />
							</Stack>
						</Router>
						<ErrorDialog
							message={userErrorMessage}
							isOpen={isOpen}
							onClose={onClose}
						/>
					</ApiClientsProvider>
				</ConfigContext.Provider>
			</FeatureFlagsProvider>
		</IntlProvider>
	);
};

export default App;
