import * as LDClient from 'launchdarkly-js-client-sdk';
import { LDContext } from 'launchdarkly-js-client-sdk';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import {
	FFKeys,
	FFTypes,
	interestingFlags,
	LDKeys,
	UserFeatureFlags,
} from '../utils/feature-flag.constants';

export * from '../utils/feature-flag.constants';

export type FFlagsContextType = {
	flags: UserFeatureFlags;
	setFlags: (pFlags: UserFeatureFlags) => void;
};

const FFlagsContext = createContext<FFlagsContextType>({
	flags: {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setFlags: () => {},
});

/**
 * Maps the sent user to a context for LaunchDarkly to understand.  The fields being returns in this
 * function can be used to target specific users in the LaunchDarkly UI.
 */
function getLDContext(user?: AppUser) {
	const custom: Record<string, string> = {};
	const userKeys: Record<string, string> = {};
	const anonymous = !user?.uuid;

	if (user) {
		Object.keys(user).forEach((key) => {
			const val = user[key as keyof AppUser];
			if (typeof val === 'string') {
				if (LDKeys.includes(key)) {
					userKeys[key] = val;
				} else {
					custom[key] = val;
				}
			}
		});
	}

	const context: LDContext = {
		kind: 'user' as any,
		custom,
		...userKeys,
		key: user?.uuid,
		email: user?.email?.toLowerCase(),
		anonymous,
	};

	// Remove the key if this is an anonymous user
	if (anonymous) {
		delete context.key;
	}

	return context;
}

type Props = { ldKey: string; appUser?: AppUser; children: React.ReactNode };

// Loads and filters the interesting LD flags for this application
export function FeatureFlagsProvider({ ldKey, appUser, children }: Props) {
	const [flags, setFlags] = useState<UserFeatureFlags>({});
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (!ldKey) {
			return;
		}

		const client = LDClient.initialize(ldKey, getLDContext(appUser));

		client.waitUntilReady().then(() => {
			const allFlags = client.allFlags();
			const callawayFlags: UserFeatureFlags = Object.fromEntries(
				interestingFlags.map((key) => [key, allFlags[key]])
			);

			setFlags(callawayFlags);
			setReady(true);
		});

		client.on(
			'change',
			(settings: Record<string, { current: FFTypes; previous: FFTypes }>) => {
				const changedFlags: UserFeatureFlags = Object.fromEntries(
					Object.entries(settings)
						.filter(([key]) => interestingFlags.includes(key as FFKeys))
						.map(([key, setting]) => [key, setting?.current])
				);

				setFlags((prevFlags) => {
					return { ...prevFlags, ...changedFlags };
				});
			}
		);
	}, [appUser, ldKey]);

	const setFlagsCB = useCallback(
		(pFlags: UserFeatureFlags) => setFlags(pFlags),
		[]
	);

	const contextValue: FFlagsContextType = useMemo(
		() => ({ flags, setFlags: setFlagsCB }),
		[flags, setFlagsCB]
	);

	return (
		<FFlagsContext.Provider value={contextValue}>
			{ready && children}
		</FFlagsContext.Provider>
	);
}

/** Returns all feature flags */
export const useFeatureFlags = () => useContext(FFlagsContext);

/** Returns specific value of sent feature flag. */
export const useFF = (key: FFKeys) => useFeatureFlags().flags[key];

/** Returns specific values of sent feature flags (multiple). */
export const useFFs = (keys: FFKeys[]) =>
	Object.entries(useFeatureFlags().flags)
		.filter(([key]) => keys.includes(key as FFKeys))
		.map(([, value]) => value);
