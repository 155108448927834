import {
	Center,
	Heading,
	Spinner,
	Stack,
	Text,
	Card,
	CardBody,
	ButtonGroup,
	Button,
	SimpleGrid,
	Tooltip,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PagedLocation } from '@smartthings/st-api-lib-ts/api__/api.smartthings.com.v1';

import { useLocations } from '../../hooks/use-locations';
import { ErrorState, InvitationPage } from './schema-app-invitation';
import { useSiam } from '../../hooks/use-siam';

export type LocationSelectionProps = {
	cancelHandler: () => void;
	invitationInfo: InvitationInfo;
	pageComplete: (page: InvitationPage, data: PagedLocation) => void;
	setErrorDialogMessage: (state: ErrorState) => void;
};

export const LocationSelection = ({
	cancelHandler,
	invitationInfo,
	pageComplete,
	setErrorDialogMessage,
}: LocationSelectionProps) => {
	const { locations, getLocationsError, setPage } = useLocations();
	const [deniedLocationIds, setDeniedLocationIds] = useState<string[]>();
	const { checkLocationsForWriteAccess } = useSiam();
	const intl = useIntl();

	// Show more will add an additional page of locations to the screen while keeping the old ones
	const showMoreHandler = useCallback(() => {
		const href = locations?._links?.next?.href ?? '';
		if (!href) {
			return;
		}

		const page = href.split('page=')[1];
		setPage(page);
	}, [locations?._links?.next?.href, setPage]);

	// Connect will pass back the location to root component to continue the flow
	const connectHandler = useCallback(
		async (location: PagedLocation) => {
			pageComplete('LocationSelection', location);
		},
		[pageComplete]
	);

	// Consolidated logic to render connect button with or without tooltip
	const renderConnectButton = useCallback(
		(location: PagedLocation) => {
			const noWriteAbility = !!deniedLocationIds?.includes(location.locationId);

			const commonButtonProps = {
				backgroundColor: 'blue.500',
				color: 'white',
				justifySelf: 'end',
				onClick: () => connectHandler(location),
				isDisabled: noWriteAbility,
			};

			const connectText = (
				<FormattedMessage id="connect" defaultMessage="Connect" />
			);

			if (noWriteAbility) {
				const tooltipMsg = intl.formatMessage({
					id: 'no-access-to-location',
					defaultMessage:
						'You do not have access to connect the schema app to this location.',
				});
				return (
					<Tooltip label={tooltipMsg}>
						{/* Allow Chakra to change BG on hover and disabled causes text to be unreadable */}
						<Button {...commonButtonProps} _hover={{ bg: 'blue.500' }}>
							{connectText}
						</Button>
					</Tooltip>
				);
			} else {
				return <Button {...commonButtonProps}>{connectText}</Button>;
			}
		},
		[connectHandler, deniedLocationIds, intl]
	);

	// Errors we send the message back up to the parent to display
	useEffect(() => {
		setErrorDialogMessage({
			message: getLocationsError?.error?.message,
		});
	}, [getLocationsError, setErrorDialogMessage]);

	// Fetch from SIAM the locations that we do not have access to
	useEffect(() => {
		async function checkPermissions() {
			if (locations?.items) {
				try {
					const locationIds = locations.items.map((loc) => loc.locationId);
					const { denied } = await checkLocationsForWriteAccess(locationIds);
					setDeniedLocationIds(denied);
				} catch (err) {
					// Not having the location SIAM data isn't a critical failure so log a warning
					// and allow code to continue by setting empty list of denied IDs
					console.warn(err);
					setDeniedLocationIds([]);
				}
			}
		}
		checkPermissions();
	}, [checkLocationsForWriteAccess, locations?.items]);

	return (
		<Card variant="outline">
			<CardBody>
				<Heading size="md">
					<FormattedMessage
						id="select-location"
						defaultMessage="Select a location to install {appName}"
						values={{ appName: invitationInfo?.schemaApp.appName }}
					/>
				</Heading>

				<Stack paddingTop="6">
					{!locations?.items || !deniedLocationIds ? (
						<Center>
							<Spinner size="xl" />
						</Center>
					) : (
						<>
							{locations.items.length === 0 && (
								<Text>
									<FormattedMessage
										id="no-locations"
										defaultMessage="You do not have any locations created on this account."
									/>
								</Text>
							)}

							<Stack spacing="8">
								<SimpleGrid columns={2} spacing="8">
									{locations.items.map((location) => (
										<React.Fragment key={location.locationId}>
											<Text fontSize="lg" alignSelf="center">
												{location.name}
											</Text>

											{renderConnectButton(location)}
										</React.Fragment>
									))}
								</SimpleGrid>

								{locations._links && (
									<Button onClick={showMoreHandler} marginTop="16">
										<FormattedMessage
											id="show-more"
											defaultMessage="Show More"
										/>
									</Button>
								)}
							</Stack>
						</>
					)}

					<ButtonGroup size="lg" paddingTop="16">
						<Button onClick={cancelHandler}>
							<FormattedMessage id="cancel" defaultMessage="Cancel" />
						</Button>
					</ButtonGroup>
				</Stack>
			</CardBody>
		</Card>
	);
};
