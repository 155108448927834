import { useCallback } from 'react';
import { useApiClients } from '../context/api-clients.context';
import {
	ErrorResponse,
	HttpResponse,
	Isa,
} from '@smartthings/st-api-lib-ts/api__/client.smartthings.com.v1';

export const useViperGetPages = () => {
	const clients = useApiClients();

	const getPages = useCallback(
		async (endpointAppId: string, locationId: string) => {
			try {
				const { data } = (await clients?.apis?.clientv1.viper.getPages({
					endpointAppId,
					type: 'oauthLink',
					locationId,
				})) as HttpResponse<Isa, void>;
				return data;
			} catch (ex) {
				console.error(ex);
				// eslint-disable-next-line no-throw-literal
				throw (ex as HttpResponse<Isa, void>)
					?.error as unknown as ErrorResponse;
			}
		},
		[clients?.apis]
	);

	return {
		getPages,
	};
};
