import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Portal,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type ErrorDialogProps = {
	message: string;
	isOpen: boolean;
	onClose: () => void;
};

const ErrorDialog = ({ message, isOpen, onClose }: ErrorDialogProps) => {
	return (
		<Portal>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<FormattedMessage id="error" defaultMessage="Error" />
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{message}</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" onClick={onClose}>
							<FormattedMessage id="close" defaultMessage="Close" />
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Portal>
	);
};

export default ErrorDialog;
