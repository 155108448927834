import {
	Button,
	Center,
	Divider,
	Heading,
	ListItem,
	Spinner,
	Stack,
	Text,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { buildHandleAxiosError } from '../utils/axiosUtil';
import ErrorDialog from './errorDialog';
import ErrorPage from './errorPage';

enum UpdateState {
	Idle = 'Idle',
	Enrollment = 'Enrollment',
	ShowMore = 'ShowMore',
}

const Channel = () => {
	const routeMatch = useMatch('/channels/:id');

	const channelId = routeMatch?.params.id || '';

	const [channel, setChannel] = useState<ChannelData>();
	const [hubs, setHubs] = useState<{
		items: HubData[];
		nextPage: number | undefined;
	}>({ items: [], nextPage: undefined });

	const [updating, setUpdating] = useState<UpdateState>(UpdateState.Idle);

	const [error, setError] = useState<PageError>();
	const [message, setMessage] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleAxiosError = useMemo(
		() =>
			buildHandleAxiosError((errorMessage: string) => {
				setMessage(errorMessage ?? '');
				onOpen();
			}),
		[onOpen]
	);

	useEffect(() => {
		(async () => {
			try {
				const { data: channelIn } = await axios.get(
					`/api/channels/${channelId}`
				);
				const { data: hubsIn } = await axios.get('/api/hubs');
				setChannel(channelIn);
				setHubs(hubsIn);
			} catch (e) {
				if (e instanceof AxiosError) {
					setError({
						code: `${e.response?.status}` || '500',
						message: e.response?.data?.message || e.message,
					});
				} else {
					console.error('Unexpected error', e);
				}
			}
		})();
	}, [channelId]);

	const waitForUpdatedChannelInfo = useCallback(
		async (hId: string, chId: string, isEnroll: boolean) => {
			// This results in waiting a max of (MAX_REFRESH_ATTEMPTS * DELAY_BETWEEN_ATTEMPTS)
			const MAX_REFRESH_ATTEMPTS = 7;
			const DELAY_BETWEEN_ATTEMPTS = 10000;

			// When an enrollment action starts on a hub it can take anywhere between 10sec to 1min.
			// This function will loop a set number of times while waiting in between each attempt.  Each
			// time through the loop the code will check if the hub data has been updated yet.
			let completedLoops = 0;
			let latestHubResponse;
			while (!latestHubResponse || completedLoops < MAX_REFRESH_ATTEMPTS) {
				// Break the loop if we have reached correct channel status based on enroll or unenroll
				if (
					isEnroll &&
					latestHubResponse &&
					latestHubResponse.data.channelIds.indexOf(chId) !== -1
				) {
					break;
				} else if (
					!isEnroll &&
					latestHubResponse &&
					latestHubResponse.data.channelIds.indexOf(chId) === -1
				) {
					break;
				}

				// eslint-disable-next-line no-await-in-loop
				await new Promise((resolve) =>
					setTimeout(resolve, DELAY_BETWEEN_ATTEMPTS)
				);
				try {
					// eslint-disable-next-line no-await-in-loop
					latestHubResponse = await axios.get(`/api/hubs/${hId}`);
				} catch (err) {
					// The user doesn't know this loop is happening so hide any errors we encounter and continue looping
					latestHubResponse = undefined;
					// eslint-disable-next-line no-console
					console.error('Checking for status response', err);
				}
				completedLoops++;
			}

			return latestHubResponse;
		},
		[]
	);

	const enroll = async (hubId: string) => {
		await handleAxiosError(async () => {
			setUpdating(UpdateState.Enrollment);
			await axios.post('/api/enroll', {
				channelId,
				hubId,
			});
			const latestHubData = await waitForUpdatedChannelInfo(
				hubId,
				channelId,
				true
			);

			const updatedHubData = hubs.items.map((hub) =>
				hub.id === hubId ? latestHubData.data : hub
			);
			setHubs({ items: updatedHubData, nextPage: hubs.nextPage });
		});
		setUpdating(UpdateState.Idle);
	};

	const unenroll = async (hubId: string) => {
		await handleAxiosError(async () => {
			setUpdating(UpdateState.Enrollment);
			await axios.post('/api/unenroll', {
				channelId,
				hubId,
			});
			const latestHubData = await waitForUpdatedChannelInfo(
				hubId,
				channelId,
				false
			);

			const updatedHubData = hubs.items.map((hub) =>
				hub.id === hubId ? latestHubData.data : hub
			);
			setHubs({ items: updatedHubData, nextPage: hubs.nextPage });
		});
		setUpdating(UpdateState.Idle);
	};

	const showMore = async () => {
		await handleAxiosError(async () => {
			setUpdating(UpdateState.ShowMore);
			const { data: nextData } = await axios.get(
				`/api/hubs?nextPage=${hubs.nextPage}`
			);
			setHubs({
				items: [...hubs.items, ...nextData.items],
				nextPage: nextData.nextPage,
			});
		});
		setUpdating(UpdateState.Idle);
	};

	if (error) {
		return <ErrorPage {...error} />;
	}

	return channel && hubs ? (
		<>
			<Heading size="md" paddingLeft={3}>
				<FormattedMessage
					id="channel-information"
					defaultMessage="Channel Information"
				/>
			</Heading>
			<UnorderedList paddingLeft={10} spacing={3}>
				<ListItem>
					<Text>
						<FormattedMessage id="name" defaultMessage="Name" />
					</Text>
					<Text>{channel.name}</Text>
				</ListItem>
				{channel.owner && (
					<ListItem>
						<Text>
							<FormattedMessage id="owner" defaultMessage="Owner" />
						</Text>
						<Text>{channel.owner}</Text>
					</ListItem>
				)}
				<ListItem>
					<Text>
						<FormattedMessage id="description" defaultMessage="Description" />
					</Text>
					<Text>{channel.description}</Text>
				</ListItem>
				{channel.termsOfServiceUrl && (
					<ListItem textDecoration="underline">
						<a
							href={channel.termsOfServiceUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FormattedMessage
								id="termsOfService"
								defaultMessage="Terms of service"
							/>
						</a>
					</ListItem>
				)}
			</UnorderedList>

			<Divider paddingTop={5} />

			<Stack spacing={5} padding={[3, 5]} paddingTop={5}>
				<Heading size="md">
					<FormattedMessage id="myHubs" defaultMessage="My Hubs" />
				</Heading>

				{hubs.items.length === 0 && (
					<Text>
						<FormattedMessage
							id="no-hubs"
							defaultMessage="User does not have any hubs"
						/>
					</Text>
				)}

				{hubs.items.map((hub) => (
					<Stack key={hub.id} spacing={2}>
						<Heading size="md">{hub.name}</Heading>
						<Text>{hub.location}</Text>
						{/* eslint-disable-next-line no-negated-condition */}
						{updating !== UpdateState.Idle ? (
							<Stack direction="row" spacing="4" paddingBottom="4">
								<Spinner />

								{updating === UpdateState.Enrollment && (
									<Text>
										<FormattedMessage
											id="updating-enrollment-msg"
											defaultMessage="Updating channel enrollment may take up to a minute"
										/>
									</Text>
								)}
							</Stack>
						) : (
							<Stack direction="row">
								{hub.channelIds &&
								hub.channelIds.find(
									(enrolledChannel) => enrolledChannel === channel.id
								) ? (
									<>
										<Button onClick={() => unenroll(hub.id)}>
											<FormattedMessage
												id="unenroll"
												defaultMessage="Unenroll"
											/>
										</Button>
										<Button
											as={Link}
											to={`/channels/${channel.id}/hub/${hub.id}`}
										>
											<FormattedMessage
												id="available-drivers"
												defaultMessage="Available Drivers"
											/>
										</Button>
									</>
								) : (
									<Button
										onClick={() => enroll(hub.id)}
										backgroundColor="blue.500"
										color="white"
									>
										<FormattedMessage id="enroll" defaultMessage="Enroll" />
									</Button>
								)}
							</Stack>
						)}
					</Stack>
				))}

				{hubs.nextPage && (
					<Stack direction="row" paddingTop="5">
						<Button
							onClick={showMore}
							backgroundColor="blue.500"
							color="white"
							disabled={updating !== UpdateState.Idle}
						>
							<FormattedMessage
								id="show-more-hubs"
								defaultMessage="Show more hubs"
							/>
						</Button>
					</Stack>
				)}
			</Stack>
			<ErrorDialog message={message} isOpen={isOpen} onClose={onClose} />
		</>
	) : (
		<Center>
			<Spinner size="xl" />
		</Center>
	);
};

export default Channel;
