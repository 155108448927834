import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';

import { useApiClients } from '../context/api-clients.context';
import {
	AcceptSchemaAppInviteResponse,
	ErrorResponse,
	HttpResponse,
} from '@smartthings/st-api-lib-ts/api__/bestow.st.internal.v20210915';

type GetInviteErrorData = {
	state?: 'Forbidden' | 'Unexpected';
	error?: AxiosError;
};

export const useSchemaAppInvitation = (invitationId: string) => {
	const [invitationInfo, setInvitationInfo] = useState<InvitationInfo>();
	const [errorState, setErrorState] = useState<GetInviteErrorData>();
	const clients = useApiClients();

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await axios.get<InvitationInfo>(
					`/api/schemaApp/invitation`,
					{
						params: {
							invitationId,
						},
					}
				);
				setInvitationInfo(data);
			} catch (ex) {
				console.error(ex);
				const err = ex as AxiosError;
				if (err?.response?.status === 400 || err?.response?.status === 403) {
					// From user perspective, no difference between 400 and 403
					setErrorState({ state: 'Forbidden', error: err });
				} else {
					setErrorState({ state: 'Unexpected', error: err });
				}
			}
		};
		getData();
	}, [invitationId]);

	const acceptInvite = useCallback(
		async (shortCode: string) => {
			try {
				const response =
					await clients?.apis?.bestow.invites.acceptSchemaAppInvite(shortCode, {
						headers: {
							'content-type': 'application/json',
						},
					});
				return response;
			} catch (ex) {
				console.error(ex);
				const err = (
					ex as HttpResponse<
						AcceptSchemaAppInviteResponse,
						void | ErrorResponse
					>
				).error as ErrorResponse;

				// 400 with particular message means the user is already a member and accept
				// failed but they can continue since they already have access to the app.
				if (
					err?.error?.code === '400' &&
					(err.error.message === 'ALREADY_A_MEMBER' ||
						err.error.message === 'CANNOT_ACCEPT_OWN_INVITE')
				) {
					return {};
				}

				throw err;
			}
		},
		[clients?.apis?.bestow.invites]
	);

	return {
		acceptInvite,
		getInviteError: errorState,
		invitationInfo,
	};
};
