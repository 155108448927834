export const LDKeys = [
	'key',
	'secondary',
	'email',
	'ip',
	'name',
	'avatar',
	'firstName',
	'lastName',
	'country',
	'os',
	'device',
];

export const CallawayFF = {
	stSchemaInvitations: 'callaway-st-schema-invitations',
} as const;

export type UserFeatureFlags = {
	[CallawayFF.stSchemaInvitations]?: boolean;
};
export type FFKeys = keyof UserFeatureFlags;
export type FFTypes = UserFeatureFlags[FFKeys];

export const interestingFlags: FFKeys[] = [CallawayFF.stSchemaInvitations];
