import axios from 'axios';

export function createInterceptor(reauthFn: Function) {
	axios.interceptors.response.use(
		(response) => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		(error) => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			if (error.response && error.response.status === 401) {
				reauthFn(error);

				// Need to return _something_ otherwise the caller will get an undefined response
				// The actual response doesn't matter that much because the reauthFn is going to be
				// redirecting the user.  We just want to avoid unnecessary console.log errors.
				return error;
			}
			return Promise.reject(error);
		}
	);
}
