import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line no-console
console.log('rendering main component');
const container = document.getElementById('root');
if (!container) {
	throw new Error('Could not find root');
}
const root = createRoot(container);
root.render(
	<ChakraProvider>
		<App />
	</ChakraProvider>
);

// eslint-disable-next-line no-console
console.log('unregistering serviceWorker');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line no-console
console.log('finished starting up');
