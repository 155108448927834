import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
	Center,
	Heading,
	Spinner,
	Stack,
	Text,
	Card,
	CardBody,
	ButtonGroup,
	Button,
	Link,
	Spacer,
	UnorderedList,
	ListItem,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { PagedLocation } from '@smartthings/st-api-lib-ts/api__/api.smartthings.com.v1';
import {
	DeviceResults,
	ErrorResponse,
	Isa,
} from '@smartthings/st-api-lib-ts/api__/client.smartthings.com.v1';

import { useViperGetPages } from '../../hooks/use-viper-get-pages';
import { InvitationPage } from './schema-app-invitation';

export type OAuthFlowAction = {
	type: 'previousPage';
};

export type OAuthFlowProps = {
	cancelHandler: () => void;
	invitationInfo: InvitationInfo;
	location: PagedLocation;
	pageComplete: (page: InvitationPage, data: OAuthFlowAction) => void;
};

type OAuthFlowState =
	| 'FetchingUrl'
	| 'WaitingForUserAuth'
	| 'FetchingDevices'
	| 'DevicesFetched';

export const OAuthFlow = (props: OAuthFlowProps) => {
	const { invitationInfo, location } = props;
	const { getPages } = useViperGetPages();

	const [viperGetPagesData, setViperGetPagesData] = useState<Isa>();
	const [getPagesError, setGetPagesError] = useState<ErrorResponse>();
	const [oAuthWindow, setOAuthWindow] = useState<Window | null>();
	const [flowState, setFlowState] = useState<OAuthFlowState>('FetchingUrl');

	const { oAuthLink, devices } = viperGetPagesData ?? {};

	// Opens OAuthLink in new tab and sets the proper state.
	// Opening URL must be done through this call to ensure proper screens are displayed.
	const openOAuthLink = useCallback((link?: string) => {
		if (!link) {
			return;
		}
		const win = window.open(link, '_blank');
		setOAuthWindow(win);
		setFlowState('WaitingForUserAuth');
	}, []);

	// Shared component for presenting a link to open URL correctly
	const LinkToOAuth = useCallback(
		({ children }: PropsWithChildren) => {
			return (
				<Link
					color="blue.500"
					onClick={() => openOAuthLink(oAuthLink)}
					isExternal
				>
					{children}
				</Link>
			);
		},
		[oAuthLink, openOAuthLink]
	);

	// OnLoad: On load we want to call `getPages()` to get the OAuth link and open it in a new tab
	useEffect(() => {
		async function initialGetPages() {
			try {
				setFlowState('FetchingUrl');
				const data = await getPages(
					invitationInfo.invitation.schemaAppId,
					location.locationId
				);
				setViperGetPagesData(data);

				// If devices is defined then it must have been previously connected
				if (!data.devices) {
					openOAuthLink(data.oAuthLink);
				}
			} catch (err) {
				const errorResponse = err as ErrorResponse;
				setGetPagesError(errorResponse);
			}
		}
		initialGetPages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); /* only want to run once */

	// Monitor OAuthWindow: When there is an OAuth window open, monitor when it is closed to we know when to query for devices
	useEffect(() => {
		if (!oAuthWindow) {
			return;
		}

		// Look at the `.closed` value periodically until it's true so we know when it's completed
		const reference = setInterval(() => {
			if (!oAuthWindow || oAuthWindow.closed) {
				clearInterval(reference);
				setOAuthWindow(undefined);
				setFlowState('FetchingDevices');
			}
		}, 1000);

		return () => clearInterval(reference);
	}, [oAuthWindow]);

	// Authorization complete: When window is closed we assume authroization is complete.
	// Call `getPages()` again to get list of devices discovered.
	useEffect(() => {
		if (flowState !== 'FetchingDevices') {
			return;
		}

		async function executeGetPages() {
			try {
				const data = await getPages(
					invitationInfo.invitation.schemaAppId,
					location.locationId
				);
				setViperGetPagesData(data);
				setFlowState('DevicesFetched');
			} catch (err) {
				const errorResponse = err as ErrorResponse;
				setGetPagesError(errorResponse);
			}
		}
		executeGetPages();
	}, [
		flowState,
		getPages,
		invitationInfo.invitation.schemaAppId,
		location.locationId,
	]);

	//
	// Render scenarios
	//

	// Error path: may happen for variety of reasons related to OAuth or Viper API calls
	if (getPagesError) {
		return (
			<Wrapper {...props} devices={devices}>
				<Text>
					<FormattedMessage
						id="authenticate-error"
						defaultMessage="An error occurred while authenticating with the app. Please select another location to attempt the operation again."
					/>
				</Text>
				{getPagesError?.error?.message && (
					<Text>{getPagesError.error?.message}</Text>
				)}
			</Wrapper>
		);
	}

	// Loading/waiting: Waiting for Viper API calls or for user to finish OAuth authentication in new tab
	if (flowState === 'FetchingUrl' || flowState === 'WaitingForUserAuth') {
		// If we already have devices returned then the location is already authorized and we can skip to the end state
		if (devices) {
			setFlowState('DevicesFetched');
		}

		return (
			<Wrapper {...props} devices={devices}>
				<Text>
					<FormattedMessage
						id="authenticate-to-app-description"
						defaultMessage="A new tab will open to the authentication page for the application. Complete the authentication process and close the tab to complete setup."
					/>
				</Text>

				{/* Browsers may block pop-up/new tab so provide them a link to click if needed */}
				{oAuthLink && (
					<Text>
						<FormattedMessage
							id="launch-oauth-link"
							defaultMessage="If the authentication page did not open, you may launch the link by <a>clicking here</a>."
							values={{
								a: (content) => <LinkToOAuth>{content}</LinkToOAuth>,
							}}
						/>
					</Text>
				)}

				{/* Always show a spinner to indicate we are waiting for either the API or for the user to authenticate */}
				<Center>
					<Spinner marginTop="8" size="xl" />
				</Center>
			</Wrapper>
		);
	}

	// Fetching devices: User has closed OAuth window and we are fetching devices via getPages()
	if (flowState === 'FetchingDevices') {
		return (
			<Wrapper {...props} devices={devices}>
				<Text>
					<FormattedMessage
						id="fetching-devices"
						defaultMessage="Authentication is complete. The list of new devices is being retrieved."
					/>
				</Text>

				<Center>
					<Spinner marginTop="8" size="xl" />
				</Center>
			</Wrapper>
		);
	}

	// Fetching devices complete: getPages() has been called to get devices which we show the user
	if (flowState === 'DevicesFetched') {
		return (
			<Wrapper {...props} devices={devices}>
				{/* No devices returned; user did not actually finish auth */}
				{!devices && (
					<Text>
						<FormattedMessage
							id="not-authenticated"
							defaultMessage="Authentication to the application was not completed. <a>Click here</a> to launch the authentication page to try again."
							values={{
								a: (content) => <LinkToOAuth>{content}</LinkToOAuth>,
							}}
						/>
					</Text>
				)}

				{/* Devices returned but is empty */}
				{devices?.length === 0 && (
					<Text>
						<FormattedMessage
							id="no-devices-found"
							defaultMessage="No devices were found after authenticating to the app."
						/>
					</Text>
				)}

				{/* Devices returned and has actual values */}
				{devices && devices?.length > 0 && (
					<>
						<Text>
							<FormattedMessage
								id="authentication-complete-with-devices"
								defaultMessage="Authentication is complete. The following devices were found."
							/>
						</Text>
						<UnorderedList>
							{devices?.map((device) => (
								<ListItem key={device.deviceId} marginLeft="4">
									{device.name}
								</ListItem>
							))}
						</UnorderedList>
					</>
				)}
			</Wrapper>
		);
	}

	// The above checks will capture all scenarios so this return will never be hit.
	// However we need to add it to make sure we always return a ReactNode to make TS happy.
	return <></>;
};

// Common Wrapper component for the pieces of UI that always exist in each scenario
function Wrapper({
	cancelHandler,
	children,
	devices,
	invitationInfo,
	location,
	pageComplete,
}: OAuthFlowProps & PropsWithChildren & { devices?: DeviceResults[] }) {
	const selectDifferentLocation = useCallback(
		() => pageComplete('OAuthFlow', { type: 'previousPage' }),
		[pageComplete]
	);

	return (
		<Card variant="outline">
			<CardBody>
				<Heading size="md">
					<FormattedMessage
						id="authenticate-to-app"
						defaultMessage="Authenticating to: {appName} on {locationName}"
						values={{
							appName: invitationInfo?.schemaApp.appName,
							locationName: location.name,
						}}
					/>
				</Heading>

				<Stack paddingTop="6">
					{children}

					<ButtonGroup size="lg" paddingTop="16">
						{/* If flow was a 'success', show close instead of cancel */}
						<Button onClick={cancelHandler}>
							{devices ? (
								<FormattedMessage id="close" defaultMessage="Close" />
							) : (
								<FormattedMessage id="cancel" defaultMessage="Cancel" />
							)}
						</Button>

						<Spacer />

						{/* Only show select alternative location if auth is not complete */}
						{!devices && (
							<Button onClick={selectDifferentLocation}>
								<FormattedMessage
									id="select-another-location"
									defaultMessage="Select alternative location"
								/>
							</Button>
						)}
					</ButtonGroup>
				</Stack>
			</CardBody>
		</Card>
	);
}
