import { AxiosError } from 'axios';

const buildSmartThingsErrorDisplay = (
	error: AxiosError<Record<string, any>>
) => {
	if (error.response) {
		// server responded with non-200 response code
		const responseBody = error.response.data;
		if (!responseBody) {
			return `unexpected error ${error.message} (code ${error.code})`;
		}
		const output = [];
		if (responseBody.code) {
			output.push(`${responseBody.code}:`);
		}

		if (responseBody.message) {
			output.push(responseBody.message);
		}

		if (responseBody.details && responseBody.details.length) {
			responseBody.details.forEach((detail: any) => {
				output.push(JSON.stringify(detail));
			});
		}

		return output.join(' ');
	}
	if (error.request) {
		return 'no response from server';
	}
	return `unexpected error ${error.message} (code ${error.code})`;
};

/**
 * Use this method to build a method that can be used to wrap axios calls to the backend to get
 * default error handling. A method to display the error must be passed to this builder.
 *
 * The method returned takes two function arguments. The first is a simple callback that should
 * make the axios request. The optional second argument allows the user to handle specific errors
 * differently. It gets passed the caught error and should return true if it should be considered
 * handled.
 */
export const buildHandleAxiosError =
	(displayError: Function) =>
	async (makeAxiosCall: Function, specificErrorHandler?: Function) => {
		try {
			return await makeAxiosCall();
		} catch (error) {
			const axiosErr = error as AxiosError<Record<string, any>>;
			if (specificErrorHandler && specificErrorHandler(axiosErr)) {
				return undefined;
			}
			displayError(buildSmartThingsErrorDisplay(axiosErr));
			return undefined;
		}
	};
