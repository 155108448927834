import { useCallback, useMemo } from 'react';
import {
	HttpResponse,
	AllowedPermissionCheckResponse,
	DeniedPermissionCheckResponse,
	PermissionCheckInvalidRequest,
	PermissionDeniedSRNString,
} from '@smartthings/st-api-lib-ts/api__/siam.st.internal.v20211004';

import { useApiClients } from '../context/api-clients.context';
import { Authorizer } from '../utils/authorize';

export const useSiam = () => {
	const clients = useApiClients();
	const authData = useMemo(() => Authorizer.getAuthData(), []);

	const checkLocationsForWriteAccess = useCallback(
		async (locationIds: string[]) => {
			// SIAM can take a list of resources and check each of them
			// Build up a map of the objects, one per location
			const resources = locationIds.map((locId) => ({
				resource: `st1:core::location/${locId}`,
				actions: ['w:locations'],
			}));

			const { data } = (await clients?.apis?.siam.permission.checkUpdate(
				{
					principal: `st1:iam::user/${authData?.uuid}`,
					resources,
				},
				{
					headers: {
						accept: 'application/json',
					},
				}
			)) as HttpResponse<
				AllowedPermissionCheckResponse | DeniedPermissionCheckResponse,
				void | PermissionCheckInvalidRequest
			>;

			// `.items` from response represents location IDs that do not have the write access.
			// Filter out these and return them.
			const deniedIds = locationIds.filter((locId) =>
				(data as DeniedPermissionCheckResponse).items?.find((deniedItem) =>
					(deniedItem as PermissionDeniedSRNString).resource?.includes(
						`location/${locId}`
					)
				)
			);
			const allowedIds = locationIds.filter(
				(locId) => !deniedIds.includes(locId)
			);

			return {
				allowed: allowedIds,
				denied: deniedIds,
			};
		},
		[authData?.uuid, clients?.apis?.siam.permission]
	);

	return {
		checkLocationsForWriteAccess,
	};
};
