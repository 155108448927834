import { useCallback, useContext, useState } from 'react';
import { Center, Container } from '@chakra-ui/react';
import { useMatch } from 'react-router-dom';
import { PagedLocation } from '@smartthings/st-api-lib-ts/api__/api.smartthings.com.v1';

import ErrorDialog from '../errorDialog';
import { InvitationDetails } from './invitation-details';
import { LocationSelection } from './location-selection';
import { ConfigContext } from '../../context/config.context';
import { OAuthFlow, OAuthFlowAction } from './oauth-flow';

export type ErrorState = {
	message?: string;
};

export type InvitationPage =
	| 'InvitationDetails'
	| 'LocationSelection'
	| 'OAuthFlow';

const PAGE_ORDER: InvitationPage[] = [
	'InvitationDetails',
	'LocationSelection',
	'OAuthFlow',
];

export const SchemaAppInvitation = () => {
	const routeMatch = useMatch('/schemaApp/:id');
	const invitationId = routeMatch?.params.id || '';

	const appConfig = useContext(ConfigContext);

	const [errorState, setErrorState] = useState<ErrorState>({});
	const closeErrorHandler = useCallback(() => setErrorState({}), []);

	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const [invitationInfo, setInvitationInfo] = useState<InvitationInfo>();
	const [location, setLocation] = useState<PagedLocation>();

	// Cancel should always redirect to Cake so define it at the container level
	const cancelHandler = useCallback(
		() => (window.location.href = appConfig.cakeUrl),
		[appConfig.cakeUrl]
	);

	// Depending on which page is complete, update the main copy of user selected data
	const pageComplete = useCallback(
		(
			page: InvitationPage,
			data: InvitationInfo | PagedLocation | OAuthFlowAction
		) => {
			switch (page) {
				case 'InvitationDetails':
					setInvitationInfo(data as InvitationInfo);
					break;
				case 'LocationSelection':
					setLocation(data as PagedLocation);
					break;
				case 'OAuthFlow':
					const oauthFlowData = data as OAuthFlowAction;
					if (oauthFlowData.type === 'previousPage') {
						setCurrentPageIndex((prev) => prev - 1);
						return;
					}
					break;
			}

			setCurrentPageIndex((prev) => prev + 1);
		},
		[]
	);

	const renderPageContent = useCallback(() => {
		const pageToRender = PAGE_ORDER[currentPageIndex];
		switch (pageToRender) {
			case 'InvitationDetails':
			default:
				return (
					<InvitationDetails
						cancelHandler={cancelHandler}
						invitationId={invitationId}
						pageComplete={pageComplete}
						setErrorDialogMessage={setErrorState}
					/>
				);
			case 'LocationSelection':
				return (
					<LocationSelection
						cancelHandler={cancelHandler}
						invitationInfo={invitationInfo as InvitationInfo}
						pageComplete={pageComplete}
						setErrorDialogMessage={setErrorState}
					/>
				);
			case 'OAuthFlow':
				return (
					<OAuthFlow
						cancelHandler={cancelHandler}
						invitationInfo={invitationInfo as InvitationInfo}
						location={location as PagedLocation}
						pageComplete={pageComplete}
					/>
				);
		}
	}, [
		cancelHandler,
		currentPageIndex,
		invitationId,
		invitationInfo,
		location,
		pageComplete,
	]);

	return (
		<Center>
			<Container maxWidth="container.sm">
				<ErrorDialog
					isOpen={!!errorState?.message}
					message={errorState?.message || ''}
					onClose={closeErrorHandler}
				/>
				{renderPageContent()}
			</Container>
		</Center>
	);
};
