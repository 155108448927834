import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import {
	ApiKeyType,
	ApiMeta,
	ApiMetaType,
	getApiClient,
	newApiConfig,
} from '@smartthings/st-api-lib-ts';
import { ConfigContext } from './config.context';

const API_KEYS = [
	ApiMeta.api.key,
	ApiMeta.bestow.key,
	ApiMeta.clientv1.key,
	ApiMeta.siam.key,
];
const API_BASE_URL_CONFIG_KEYS = {
	[ApiMeta.api.key]: '/api/proxy/gateway',
	[ApiMeta.bestow.key]: '/api/proxy/gateway',
	[ApiMeta.clientv1.key]: '/api/proxy/clientv1',
	[ApiMeta.siam.key]: '/api/proxy/siam',
};

type ApiClientMap = {
	[Key in ApiKeyType]: InstanceType<ApiMetaType[Key]['api']>;
};

type ApiClientsContextType = {
	apis?: ApiClientMap;
};

const ApiClientsContext = createContext<ApiClientsContextType | undefined>(
	{} as ApiClientsContextType
);

export const useApiClients = () => useContext(ApiClientsContext);

export function ApiClientsProvider({ children }: PropsWithChildren) {
	const appConfig = useContext(ConfigContext);

	const apiClientsContext = useMemo(() => {
		if (!appConfig) {
			return undefined;
		}

		const apis = API_KEYS.map((key) => (ApiMeta as Record<string, any>)[key])
			.map(({ key, api }) => {
				const config = newApiConfig(api, {});
				config.baseUrl = API_BASE_URL_CONFIG_KEYS[key];
				return getApiClient(config);
			})
			.reduce((clients, current) => {
				// Reduce the array of API clients to key,value object for easier
				return {
					...clients,
					[current.apiKey as string]: current,
				};
			}, {}) as unknown as ApiClientMap;

		return { apis };
	}, [appConfig]);

	return (
		<ApiClientsContext.Provider value={apiClientsContext}>
			{children}
		</ApiClientsContext.Provider>
	);
}
