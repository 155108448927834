import {
	Button,
	Center,
	Divider,
	Heading,
	ListItem,
	Spinner,
	Stack,
	Text,
	UnorderedList,
	useDisclosure,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import { buildHandleAxiosError } from '../utils/axiosUtil';
import ErrorDialog from './errorDialog';

const Drivers = () => {
	const { formatMessage } = useIntl();
	const routeMatch = useMatch('/channels/:channelId/hub/:hubId');

	const { channelId, hubId } = routeMatch?.params || {};

	const [channel, setChannel] = useState<ChannelData>();
	const [hub, setHub] = useState<HubData>();
	const [drivers, setDrivers] = useState<DriverData[]>([]);

	const [updating, setUpdating] = useState(false);

	const [message, setMessage] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleAxiosError = useMemo(
		() =>
			buildHandleAxiosError((errorMessage: string) => {
				setMessage(errorMessage ?? '');
				onOpen();
			}),
		[onOpen]
	);

	useEffect(() => {
		(async () => {
			const { data: channelIn } = await axios.get(`/api/channels/${channelId}`);
			const { data: hubIn } = await axios.get(`/api/hubs/${hubId}`);
			const { data: driversIn } = await axios.get(
				`/api/channels/${channelId}/hubs/${hubId}/drivers`
			);

			setChannel(channelIn);
			setHub(hubIn);
			setDrivers(driversIn);
		})();
	}, [channelId, hubId]);

	const installDriver = async (driverId: string) => {
		await handleAxiosError(async () => {
			setUpdating(true);
			const { data: driversIn } = await axios.post('/api/drivers/install', {
				channelId,
				hubId,
				driverId,
			});
			if (driversIn) {
				setDrivers(driversIn);
			}
		});
		setUpdating(false);
	};

	const uninstallDriver = async (driverId: string) => {
		await handleAxiosError(
			async () => {
				setUpdating(true);
				const { data: driversIn } = await axios.post('/api/drivers/uninstall', {
					channelId,
					hubId,
					driverId,
				});
				if (driversIn) {
					setDrivers(driversIn);
				}
			},
			(error: AxiosError<Record<string, any>>) => {
				if (error.response?.data?.error === 'driver in use') {
					const errorMessage = formatMessage({
						id: 'cannot-uninstall-driver-in-use',
						defaultMessage: 'Cannot uninstall because the driver is in use.',
					});
					setMessage(errorMessage);
					onOpen();
					return true;
				}
				return false;
			}
		);
		setUpdating(false);
	};

	return channel && hub && drivers ? (
		<>
			<Heading size="md" paddingTop={3}>
				{channel.name}
			</Heading>
			<Heading size="md" paddingTop={5}>
				{hub.name}
			</Heading>
			<Heading size="md">{hub.location}</Heading>

			<Divider paddingTop={5} />

			{drivers.length === 0 ? (
				<Text paddingTop={5}>
					<FormattedMessage
						id="no-drivers-found"
						defaultMessage="No drivers for this channel."
					/>
				</Text>
			) : (
				drivers.map((driver) => (
					<Stack key={driver.id} paddingTop={5}>
						<UnorderedList spacing={2}>
							<ListItem>
								<Text>
									<FormattedMessage id="name" defaultMessage="Name" />
								</Text>
								<Text>{driver.name}</Text>
							</ListItem>
							{driver.publisher && (
								<ListItem>
									<Text>
										<FormattedMessage
											id="publisher"
											defaultMessage="Publisher/Developer"
										/>
									</Text>
									<Text>{driver.publisher}</Text>
								</ListItem>
							)}
							{driver.description && (
								<ListItem>
									<Text>
										<FormattedMessage
											id="description"
											defaultMessage="Description"
										/>
									</Text>
									<Text>{driver.description}</Text>
								</ListItem>
							)}
							{driver.permissions && driver.permissions.length && (
								<ListItem>
									<Text>
										<FormattedMessage
											id="permissions"
											defaultMessage="Permissions"
										/>
									</Text>
									<Text>
										<UnorderedList spacing={1}>
											{driver.permissions.map((permission) => (
												<ListItem key={permission.name}>
													<Text>
														{permission.name}: {permission.description}
													</Text>
												</ListItem>
											))}
										</UnorderedList>
									</Text>
								</ListItem>
							)}
						</UnorderedList>
						{updating ? (
							<Spinner />
						) : (
							<Stack direction="row" paddingLeft={3}>
								{driver.installed ? (
									<Button onClick={() => uninstallDriver(driver.id)}>
										<FormattedMessage
											id="uninstall"
											defaultMessage="Uninstall"
										/>
									</Button>
								) : (
									<Button
										onClick={() => installDriver(driver.id)}
										backgroundColor="blue.500"
										color="white"
									>
										<FormattedMessage id="install" defaultMessage="Install" />
									</Button>
								)}
							</Stack>
						)}
					</Stack>
				))
			)}
			<ErrorDialog message={message} isOpen={isOpen} onClose={onClose} />
		</>
	) : (
		<Center>
			<Spinner size="xl" />
		</Center>
	);
};

export default Drivers;
