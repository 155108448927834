import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Center,
	Heading,
	Flex,
	Spinner,
	Stack,
	Text,
	Card,
	CardBody,
	Image,
	Alert,
	AlertIcon,
	ButtonGroup,
	Button,
	Spacer,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorResponse } from '@smartthings/st-api-lib-ts/api__/bestow.st.internal.v20210915';

import { useSchemaAppInvitation } from '../../hooks/use-schema-app-invitation';
import { ErrorState, InvitationPage } from './schema-app-invitation';

export type InvitationDetailsProps = {
	cancelHandler: () => void;
	invitationId: string;
	pageComplete: (page: InvitationPage, data: InvitationInfo) => void;
	setErrorDialogMessage: (state: ErrorState) => void;
};

export const InvitationDetails = ({
	cancelHandler,
	invitationId,
	pageComplete,
	setErrorDialogMessage,
}: InvitationDetailsProps) => {
	const { acceptInvite, getInviteError, invitationInfo } =
		useSchemaAppInvitation(invitationId);
	const [isExecutingAccept, setIsExecutingAccept] = useState(false);

	// Get static translations
	const intl = useIntl();
	const { INTL_APP_ICON, INTL_PROCEED } = useMemo(
		() => ({
			INTL_APP_ICON: intl.formatMessage({
				id: 'app-icon',
				defaultMessage: 'Application icon',
			}),
			INTL_PROCEED: intl.formatMessage({
				id: 'proceed',
				defaultMessage: 'Proceed',
			}),
		}),
		[intl]
	);

	// Unexpected errors we send the message back up to the parent to display
	useEffect(() => {
		if (getInviteError?.state === 'Unexpected') {
			setErrorDialogMessage({
				message: (getInviteError?.error?.response?.data as Record<string, any>)
					?.message,
			});
		}
	}, [
		getInviteError?.error?.response?.data,
		getInviteError?.state,
		setErrorDialogMessage,
	]);

	// Accepts the invite while showing loading indicators
	const proceedHandler = useCallback(async () => {
		if (!invitationInfo) {
			return;
		}
		setIsExecutingAccept(true);

		try {
			// Only need to call acceptInvite when the user does NOT already have access to the app
			if (!invitationInfo.hasReadAccessToSchemaApp) {
				await acceptInvite(invitationInfo.invitation.shortCode);
			}
			pageComplete('InvitationDetails', invitationInfo);
		} catch (ex) {
			// Some errors we will have a nicer message for while others we will print the error from the API
			if ((ex as ErrorResponse).error?.message === 'INVITATION_LIMIT_REACHED') {
				setErrorDialogMessage({
					message: intl.formatMessage({
						id: 'invitation-limit-reached',
						defaultMessage:
							'The invitation has reached the maximum number of accepted users.',
					}),
				});
			} else {
				setErrorDialogMessage({
					message: (ex as ErrorResponse).error?.message,
				});
			}
		} finally {
			setIsExecutingAccept(false);
		}
	}, [acceptInvite, intl, invitationInfo, pageComplete, setErrorDialogMessage]);

	// 403 / forbidden scenarios
	// This can happen if the invitation is expired or app is not valid anymore.  These are masked as
	// 403 to give only minimal information to the end user to not expose platform details.
	if (getInviteError?.state === 'Forbidden') {
		return (
			<Card variant="outline">
				<CardBody>
					<Heading size="md">
						<FormattedMessage id="no-access" defaultMessage="No Access" />
					</Heading>

					<Stack paddingTop="6">
						<Text>
							<FormattedMessage
								id="invitation-no-access"
								defaultMessage="You do not have permission to access this invitation."
							/>
						</Text>
						<Text>
							<FormattedMessage
								id="invitation-no-access-recovery"
								defaultMessage="To access, please contact the Developer."
							/>
						</Text>

						<ButtonGroup size="lg" paddingTop="6">
							<Button
								backgroundColor="blue.500"
								color="white"
								onClick={cancelHandler}
							>
								<FormattedMessage id="close" defaultMessage="Close" />
							</Button>
						</ButtonGroup>
					</Stack>
				</CardBody>
			</Card>
		);
	}

	// Loading
	// Spinner while getting data for invitation/schema app
	if (!invitationInfo) {
		return (
			<Center>
				<Spinner size="xl" />
			</Center>
		);
	}

	// Happy path
	// Show the invitation and app information
	const { schemaApp, invitation, hasReadAccessToSchemaApp } = invitationInfo;
	return (
		<>
			<Heading size="md">
				{/* Title different if already accepted */}
				{hasReadAccessToSchemaApp ? (
					<FormattedMessage
						id="invitation-accepted"
						defaultMessage="Invitation accepted"
					/>
				) : (
					<FormattedMessage
						id="invited-to-test"
						defaultMessage="You have been invited to test {appName}"
						values={{ appName: schemaApp?.appName }}
					/>
				)}
			</Heading>

			<Stack spacing="4" paddingTop="2">
				{/* Additional text if already accepted */}
				{hasReadAccessToSchemaApp && (
					<Text>
						<FormattedMessage
							id="previously-accepted-invite"
							defaultMessage="You have previously accepted the invitation for {appName}."
							values={{ appName: schemaApp?.appName }}
						/>
					</Text>
				)}

				<Text>{invitation?.description}</Text>

				{/* Schema app metadata */}
				<Card variant="outline">
					<CardBody>
						<Flex gap="4" flexDirection={{ base: 'column', md: 'row' }}>
							<Image
								src={schemaApp?.icon}
								alt={INTL_APP_ICON}
								width="240px"
								height="240px"
								flex="0 0 auto"
								alignSelf="center"
							/>

							<Flex flex="1 1 auto" flexDirection="column">
								<Heading size="sm" paddingBottom="4">
									{schemaApp?.appName}
								</Heading>

								<Flex gap="2">
									<Text as="b">
										<FormattedMessage
											id="developer-label"
											defaultMessage="Developer:"
										/>
									</Text>
									<Text>{schemaApp?.partnerName}</Text>
								</Flex>

								<Flex gap="2">
									<Text as="b">
										<FormattedMessage
											id="email-label"
											defaultMessage="Email:"
										/>
									</Text>
									<Text wordBreak="break-all">{schemaApp?.userEmail}</Text>
								</Flex>
							</Flex>
						</Flex>
					</CardBody>
				</Card>

				{/* Warning about unsupported app */}
				<Alert status="warning" borderRadius="6px">
					<AlertIcon />
					<Stack>
						<Heading size="sm">
							<FormattedMessage
								id="proceed-with-caution"
								defaultMessage="Proceed with caution"
							/>
						</Heading>
						<Text>
							<FormattedMessage
								id="proceed-with-caution-description"
								defaultMessage="Installing unsupported integrations may be harmful or not work as expected. You are responsible for use of this integration."
							/>
						</Text>
					</Stack>
				</Alert>

				<ButtonGroup size="lg">
					<Button onClick={cancelHandler}>
						<FormattedMessage id="cancel" defaultMessage="Cancel" />
					</Button>
					<Spacer />
					<Button
						backgroundColor="blue.500"
						color="white"
						isLoading={isExecutingAccept}
						loadingText={INTL_PROCEED}
						onClick={proceedHandler}
					>
						<FormattedMessage id="proceed" defaultMessage="Proceed" />
					</Button>
				</ButtonGroup>
			</Stack>
		</>
	);
};
