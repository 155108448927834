import { useEffect, useState } from 'react';
import {
	ErrorResponse,
	PagedLocations,
	HttpResponse,
} from '@smartthings/st-api-lib-ts/api__/api.smartthings.com.v1';
import { useApiClients } from '../context/api-clients.context';

export const useLocations = () => {
	const [locations, setLocations] = useState<PagedLocations>();
	const [errorState, setErrorState] = useState<ErrorResponse>();
	const [page, setPage] = useState<string>();
	const clients = useApiClients();

	useEffect(() => {
		const getLocations = async () => {
			if (!clients?.apis?.api) {
				return Promise.resolve();
			}

			try {
				const { data } = await clients?.apis?.api.locations.listLocations({
					// limit: 2, //Enable smaller limit to test paging easier
					...(page && { page }), // include only if defined
				});
				setLocations((prev) => ({
					items: [...(prev?.items || []), ...data.items],
					_links: data._links,
				}));
			} catch (ex) {
				console.error(ex);
				setErrorState(
					(ex as HttpResponse<PagedLocations, void | ErrorResponse>)
						.error as ErrorResponse
				);
			}
		};
		getLocations();
	}, [clients?.apis?.api, page]);

	return {
		getLocationsError: errorState,
		locations,
		setPage,
	};
};
