import { useContext, useMemo } from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ErrorPage from './components/errorPage';
import Channel from './components/channel';
import Drivers from './components/drivers';
import { SchemaAppInvitation } from './components/schema-app-invitation/schema-app-invitation';
import { ConfigContext } from './context/config.context';
import { useFF } from './context/feature-flags.context';

const AppRoutes = () => {
	const appConfig = useContext(ConfigContext);
	const schemaInvitationsEnabled = useFF('callaway-st-schema-invitations');
	const isSchemaAppInvitesRoute = useMatch('/schemaApp/:id');

	const intl = useIntl();
	const { INTL_DOC_TITLE_DRIVERS, INTL_DOC_TITLE_INVITATIONS } = useMemo(
		() => ({
			INTL_DOC_TITLE_DRIVERS: intl.formatMessage({
				id: 'doc-title-drivers',
				defaultMessage: 'SmartThings Channel and Drivers',
			}),
			INTL_DOC_TITLE_INVITATIONS: intl.formatMessage({
				id: 'doc-title-invitation',
				defaultMessage: 'SmartThings Invitation',
			}),
		}),
		[intl]
	);

	// Block access to schemaApp invites unless FF is on.  We can eventually remove this.
	if (isSchemaAppInvitesRoute && !schemaInvitationsEnabled) {
		window.location.href = appConfig.cakeUrl;
		return <></>;
	}

	// Set the document title depending on route
	if (isSchemaAppInvitesRoute) {
		document.title = INTL_DOC_TITLE_INVITATIONS;
	} else {
		document.title = INTL_DOC_TITLE_DRIVERS;
	}

	return (
		<Routes>
			<Route path="/channels/:id" element={<Channel />} />
			<Route path="/channels/:channelId/hub/:hubId" element={<Drivers />} />
			<Route path="/schemaApp/:id" element={<SchemaAppInvitation />} />
			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
};

export default AppRoutes;
