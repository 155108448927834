import PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

function getErrorMessage(code: string) {
	if (code === '404') {
		return {
			id: 'page-not-found',
			defaultMessage: 'Page Not Found',
		};
	}
	if (code === '403') {
		return {
			id: 'forbidden',
			defaultMessage: 'Forbidden',
		};
	}

	return {
		id: 'unexpected-error',
		defaultMessage: 'Unexpected Error',
	};
}

const ErrorPage = ({
	code = '404',
	message,
}: {
	code?: string;
	message?: string;
}) => {
	return (
		<div className="error-page">
			<h1>
				<Heading size="4xl">{code}</Heading>
				<Heading size="lg">
					<FormattedMessage {...getErrorMessage(code.toString())} />
				</Heading>
			</h1>
			<div>{message}</div>
		</div>
	);
};

ErrorPage.propTypes = {
	code: PropTypes.string,
	message: PropTypes.string,
};

export default ErrorPage;
